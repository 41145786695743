import React, { useState } from 'react';
import "../Style/iletisim.css";
import Modal from './common/Modal';
import emailjs from 'emailjs-com';
import '@fortawesome/fontawesome-free/css/all.min.css';

function İletisim() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [agreed, setAgreed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formStatus, setFormStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    // emailjs.send(serviceID, templateID, templateParams, userID)
    emailjs.send(
      'service_ptkog0i', 
      'template_mynbtgh', 
      { 
        name: formData.name, 
        email: formData.email, 
        message: formData.message
      }, 
      'kYzFCQwOmRVkSt5MH'
    )
    .then(function(response) {
      console.log('Başarılı!', response.status, response.text);
      setFormStatus('success');
      setFormData({ name: "", email: "", message: "" });
      setAgreed(false);
    }, function(err) {
      console.log('Başarısız...', err);
      setFormStatus('error');
    })
    .finally(() => {
      setIsSubmitting(false);
      setTimeout(() => setFormStatus(null), 5000);
    });
  };

  return (
    <div className="iletisim-container">
      <div className="iletisim-header">
        <h1>İletişim</h1>
        <p>Yazılarınızı göndermek veya bizimle iletişime geçmek için aşağıdaki formu kullanabilirsiniz</p>
      </div>
      
      <div className="iletisim-content">
        <div className="iletisim-social">
          <div className="social-links">
            <a href="https://www.instagram.com/turkiyetipolojidergisi/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.twitter.com/turkiyetipoloji" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="mailto:turkiyetipolojidergisi@gmail.com">
              <i className="far fa-envelope"></i>
            </a>
          </div>
          <div className="contact-info">
            <p>
              <i className="far fa-envelope"></i>
              <span>turkiyetipolojidergisi@gmail.com</span>
            </p>
          </div>
        </div>
        
        <form onSubmit={handleSubmit} className="contact-form">
          {formStatus === 'success' && <div className="form-message success">Mesajınız başarıyla gönderildi!</div>}
          {formStatus === 'error' && <div className="form-message error">Bir hata oluştu. Lütfen tekrar deneyin.</div>}
          
          <div className="form-group">
            <label htmlFor="name">İsim</label>
            <input 
              type="text" 
              id="name"
              name="name"
              placeholder="Adınız ve soyadınız" 
              value={formData.name} 
              onChange={handleChange} 
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">E-posta</label>
            <input 
              type="email" 
              id="email"
              name="email"
              placeholder="E-posta adresiniz" 
              value={formData.email} 
              onChange={handleChange} 
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Mesaj</label>
            <textarea 
              id="message"
              name="message"
              placeholder="Mesajınızı buraya yazınız" 
              value={formData.message} 
              onChange={handleChange} 
              required
            />
          </div>

          <div className="form-check">
            <input 
              type="checkbox" 
              id="agreement"
              checked={agreed} 
              onChange={(e) => setAgreed(e.target.checked)} 
              required
            />
            <label htmlFor="agreement" onClick={(e) => { e.preventDefault(); setIsModalOpen(true); }}>
              <strong>Kuralları</strong> okudum ve onaylıyorum.
            </label>
          </div>

          <button 
            type="submit" 
            className="form-submit-btn" 
            disabled={!agreed || isSubmitting}
          >
            {isSubmitting ? "Gönderiliyor..." : "Gönder"}
          </button>
        </form>
      </div>

      <Modal 
        open={isModalOpen} 
        onClose={() => {setIsModalOpen(false); setAgreed(true);}} 
      />
    </div>
  );
}

export default İletisim;
