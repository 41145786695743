import React, { useState, useRef } from 'react';
import "../Style/dergi.css";

const dergiler = [
  { 
    id: 1, 
    name: "Hepimiz Birer ENTJ Olmalıyız", 
    image: 'https://i.imgur.com/ig3PziG.png', 
    link: 'https://docdro.id/VLNjBLH',
    description: "Kişilik tiplerinin incelenmesi ve ENTJ tipi hakkında kapsamlı bir analiz."
  },
  { 
    id: 2, 
    name: "Fonksiyonlar ve Ejderhalar", 
    image: 'https://i.imgur.com/xDcdr7s.jpeg', 
    link: 'https://docdro.id/HkRZCT8',
    description: "Bilişsel fonksiyonların mitolojik sembolizmle ilişkisini inceleyen çalışma."
  },
  { 
    id: 3, 
    name: "Güneşli OPS", 
    image: 'https://i.imgur.com/eMTCOM7.png', 
    link: 'https://docdro.id/adMEIYo',
    description: "Objektif Kişilik Sistemi'nin güneş sistemi metaforu ile açıklanması."
  },
];

function Dergi() {
  const [activeId, setActiveId] = useState(1);
  const carouselRef = useRef(null);

  const handleSelect = (id) => {
    setActiveId(id);
  };

  const scrollLeft = () => {
    if (activeId > 1) {
      setActiveId(activeId - 1);
    }
  };

  const scrollRight = () => {
    if (activeId < dergiler.length) {
      setActiveId(activeId + 1);
    }
  };

  return (
    <div className="dergi-section section">
      <div className="container">
        <div className="carousel-container">
          <button 
            className="carousel-nav carousel-prev" 
            onClick={scrollLeft}
            disabled={activeId === 1}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          
          <div className="dergi-carousel" ref={carouselRef}>
            {dergiler.map((dergi) => (
              <div 
                className={`dergi-card ${activeId === dergi.id ? 'active' : ''}`} 
                key={dergi.id}
                onClick={() => handleSelect(dergi.id)}
                style={{
                  '--distance': Math.abs(activeId - dergi.id),
                  '--position': dergi.id - activeId
                }}
              >
                <div className="dergi-image-container">
                  <img src={dergi.image} alt={`Sayı ${dergi.id} - ${dergi.name}`} />
                </div>
                <div className="dergi-info">
                  <h3>Sayı {dergi.id}</h3>
                  <h4>{dergi.name}</h4>
                  <a href={dergi.link} target="_blank" rel="noopener noreferrer" className="btn">
                    İndir
                  </a>
                </div>
              </div>
            ))}
          </div>
          
          <button 
            className="carousel-nav carousel-next" 
            onClick={scrollRight}
            disabled={activeId === dergiler.length}
          >
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
        
        <div className="dergi-dots">
          {dergiler.map((dergi) => (
            <span 
              key={dergi.id} 
              className={`dergi-dot ${activeId === dergi.id ? 'active' : ''}`}
              onClick={() => handleSelect(dergi.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Dergi;