import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import '../../Style/navbar.css';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="navbar-container">
        <h1>Türkiye Tipoloji Dergisi</h1>
        
        <div className={`navbar-menu ${menuOpen ? 'active' : ''}`}>
          <Link to="/" className='ana' onClick={() => setMenuOpen(false)}>Anasayfa</Link>
          <Link to="/hakkimizda" className='kapa' onClick={() => setMenuOpen(false)}>Hakkımızda</Link>
          <Link to="/duyurular" className='duy' onClick={() => setMenuOpen(false)}>Duyurular</Link>
          <Link to="/iletisim" className='ama' onClick={() => setMenuOpen(false)}>İletişim</Link>
          <Link to="/dergi" className='derg' onClick={() => setMenuOpen(false)}>Dergi</Link>
        </div>
        
        <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;