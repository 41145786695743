import React, { useState } from 'react';
import "../Style/duyurular.css";

function Duyurular() {
  const duyurular = [
    {
      id: 1,
      baslik: '1. Sayımız Çıktı!',
      tarih: '10 Haziran 2023',
      aciklama: "Türkiye Tipoloji Dergisi'nin 1. sayısı çıktı.",
      okundu: false
    },
    {
      id: 2,
      baslik: 'Yazı Alımlarımız Başladı!',
      tarih: '10 Temmuz 2023',
      aciklama: 'Yazılarınızı 23 Temmuz tarihine kadar gönderebilirsiniz.',
      okundu: false
    },
    {
      id: 3,
      baslik: "Yazı alımları için son gün 31 Temmuz'a uzatıldı!",
      tarih: '24 Temmuz 2023',
      aciklama: 'Yazılarınızı 31 Temmuz tarihine kadar gönderebilirsiniz.',
      okundu: false
    },
  ];

  const [announcements, setAnnouncements] = useState(duyurular);
  const duyurularTers = [...announcements].reverse();

  const markAsRead = (id) => {
    setAnnouncements(prev => 
      prev.map(duyuru => 
        duyuru.id === id ? {...duyuru, okundu: !duyuru.okundu} : duyuru
      )
    );
  };

  return (
    <div className="duyurular-section">
      <div className="duyurular-header">
        <h1>Duyurular</h1>
        <p>Türkiye Tipoloji Dergisi ile ilgili en son haberleri takip edin</p>
      </div>
      
      <div className="duyurular-container">
        {duyurularTers.map(duyuru => (
          <div 
            className={`duyuru-card ${duyuru.okundu ? 'okundu' : ''}`} 
            key={duyuru.id}
            onClick={() => markAsRead(duyuru.id)}
          >
            <div className="duyuru-content">
              <div className="duyuru-header">
                <div className="duyuru-status"></div>
                <span className="duyuru-tarih">{duyuru.tarih}</span>
              </div>
              <h2 className="duyuru-baslik">{duyuru.baslik}</h2>
              <p className="duyuru-aciklama">{duyuru.aciklama}</p>
            </div>
            <div className="duyuru-footer">
              <button className="duyuru-btn">Detaylar</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Duyurular;